/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery('.image-carousel').owlCarousel({
          //rtl:true,
          autoplay:true,
          loop:true,
          margin:0,
          nav:true,
          items:1,
          dots:false,
        });

        jQuery('.mobile-menu').click(function(){
          if( jQuery(this).hasClass('open') ){
            jQuery('.nav-primary').removeClass('active');
            jQuery(this).removeClass('open');
          }else{
            jQuery('.nav-primary').addClass('active');
            jQuery(this).addClass('open');
          }
        });

        var grid = jQuery('#projecten-list').isotope({
          itemSelector: '.projecten-item',
          //layoutMode: 'fitRows'
        });
        // filter functions
        var filterFns = {
          // show if number is greater than 50
          numberGreaterThan50: function() {
            var number = jQuery(this).find('.number').text();
            return parseInt( number, 10 ) > 50;
          },
          // show if name ends with -ium
          ium: function() {
            var name = jQuery(this).find('.name').text();
            return name.match( /ium$/ );
          }
        };
        // bind filter button click
        jQuery('#projecten-filter').on( 'click', 'a', function() {
          var filterValue = jQuery( this ).attr('data-filter');
          // use filterFn if matches value
          filterValue = filterFns[ filterValue ] || filterValue;
          grid.isotope({ filter: filterValue });
        });
        // change is-checked class on buttons
        jQuery('#projecten-filter').each( function( i, buttonGroup ) {
          var buttonGroup = jQuery( buttonGroup );
          buttonGroup.on( 'click', 'a', function() {
            buttonGroup.find('.is-checked').removeClass('is-checked');
            jQuery( this ).addClass('is-checked');
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
